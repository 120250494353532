import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, HOME_PREFIX_PATH } from "configs/AppConfig";
import React from "react";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import("views/auth-views/authentication/login"))
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() => import("views/auth-views/authentication/forgot-password"))
  }
];

export const protectedRoutes = [
  {
    key: "adminpage",
    path: `${APP_PREFIX_PATH}/adminpage`,
    component: React.lazy(() => import("views/app-views/adminpage")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "apps.project.scrumboard",
    path: `${APP_PREFIX_PATH}/apps/project/incomingRequest`,
    component: React.lazy(() => import("views/app-views/apps/project/scrumboard"))
  },
  {
    key: "apps.search",
    path: `${APP_PREFIX_PATH}/apps/search`,
    component: React.lazy(() => import("views/app-views/apps/search/search"))
  },
  {
    key: "apps.accounts",
    path: `${APP_PREFIX_PATH}/apps/accounts`,
    component: React.lazy(() => import("views/app-views/apps/accounts/accounts"))
  },
  {
    key: "apps.clients",
    path: `${APP_PREFIX_PATH}/apps/clients`,
    component: React.lazy(() => import("views/app-views/apps/clients"))
  },
  {
    key: "pages.user-list",
    path: `${APP_PREFIX_PATH}/pages/user-list`,
    component: React.lazy(() => import("views/app-views/pages/user-list"))
  },
  {
    key: "pages.setting",
    path: `${APP_PREFIX_PATH}/pages/settings/*`,
    component: React.lazy(() => import("views/app-views/pages/setting"))
  },
  {
    key: "pages.profile",
    path: `${APP_PREFIX_PATH}/pages/profile`,
    component: React.lazy(() => import("views/app-views/pages/profile"))
  }
];

export const homeRoutes = [
  {
    key: "lock-screen",
    path: `${AUTH_PREFIX_PATH}/lock-screen`,
    component: React.lazy(() => import("views/auth-views/authentication/lock-screen")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.homepage",
    path: `${HOME_PREFIX_PATH}/pages/homepage`,
    component: React.lazy(() => import("views/home-views/pages/homepage")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.aboutus",
    path: `${HOME_PREFIX_PATH}/pages/aboutus`,
    component: React.lazy(() => import("views/home-views/pages/aboutus")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.revenform",
    path: `${HOME_PREFIX_PATH}/pages/revenform`,
    component: React.lazy(() => import("views/home-views/pages/revenform")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.patient.upload",
    path: `${HOME_PREFIX_PATH}/patient-upload`,
    component: React.lazy(() => import("views/home-views/pages/patient-upload")),
    meta: {
      blankLayout: true
    }
  },
  {
    key: "home.pages.admin.upload",
    path: `${HOME_PREFIX_PATH}/admin-upload`,
    component: React.lazy(() => import("views/home-views/pages/admin-upload")),
    meta: {
      blankLayout: true
    }
  }
];
